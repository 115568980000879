import { useState } from 'react';
import Head from 'next/head';
import styles from '../styles/Home.module.css';
import 'flatpickr/dist/themes/material_blue.css';

import { Box, Text } from '@chakra-ui/react';
import Spinner from '@components/Spinner'

export default function Home() {


  return (
    <div className={styles.container}>
      <Head>
        <title>Oddular Reports</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
        <Box>

        </Box>
      </div>
  );
}
